import { IMetricTelemetry, IExceptionTelemetry, IEventTelemetry, ITraceTelemetry } from "@microsoft/applicationinsights-web"
import { UIConstants } from "./Constants";
import ITenant from "./Tenant.model";
import { IDropdownOption } from "@fluentui/react";
declare var OpenFDA: any;
function loadDynamicScript(url: string, loaded?: () => void) {
  const mainElement: HTMLScriptElement = document.createElement('script');
  mainElement.src = url;
  mainElement.type = 'text/javascript';
  mainElement.async = false;
  document.getElementsByTagName('head')[0].appendChild(mainElement);

  if (loaded) {
    mainElement.onload = () => {
      loaded();
    };
  }
}

export const URLHelper = {
  GetCycles: (tenantId:string, fiscalYear: string) => { return `api/request/cycles/${tenantId}/${fiscalYear}` },
  GetPermissions: (tenantId: string, roleId: string) => { return `api/request/rolepermissions/${tenantId}/${roleId}` },
  GetRoles: (tenantId: string) => { return `api/request/${tenantId}/roles` },
  GetAdminViewRoles: (tenantId: string) => { return `api/request/${tenantId}/roles/admin` },
  GetAssetsFromAuthZ: () => { return `api/request/assets` },
  GetAttributeValues: (tenantId: string, attributeName: string, searchText: string) => { return searchText ? `api/tenant/${tenantId}/attributes/${attributeName}/${searchText}` : `api/tenant/${tenantId}/attributes/${attributeName}` },
  GetMyAccessRequest: (tenantId: string) => { return `api/request/${tenantId}/myaccessrequest` },
  GetAllSupplierUserRequests: (tenantId: string) => { return `api/request/${tenantId}/supplierusers` },
  GetSupplierUserRequests: (tenantId: string, userId: string, supplierId: string) => { return `api/request/${tenantId}/supplierusers/supplier/${supplierId}/user/${userId}` },
  GetSupplierUserRequestsByUserEmail: (tenantId: string, userId: string) => { return `api/request/${tenantId}/supplierusers/user/${userId}` },
  GetSupplierUserRequestsBySupplierId: (tenantId: string, supplierId: string) => { return `api/request/${tenantId}/supplierusers/supplier/${supplierId}` },
  GetSupplierUserAuthActivityBasedOnSearch: (tenantId: string, supplierId: string, userId: string) => { return `api/request/${tenantId}/supplier/${supplierId}/user/${userId}/supplierauthactivity` },
  GetMyAccess: (tenantId: string) => { return `api/request/${tenantId}/myaccess` },
  IsTenantAdmin: (tenantId: string) => { return `api/request/${tenantId}/istenantadmin` },
  AllowUserManagement: (tenantId: string) => { return `api/request/${tenantId}/allowusermanagement` },
  AllowRequestOnBehalf: (tenantId: string) => { return `api/request/${tenantId}/allowrequestonbehalf` },
  IsTenantAuditor: (tenantId: string) => { return `api/request/${tenantId}/istenantauditor` },
  GetApprovalStatusDetails: (requestId: string) => { return `api/request/requestid/${requestId}/approvalstatus` },
  GetApprovalStatusDetailsForTenant: (tenantId: string) => { return `api/tenant/${tenantId}/approvalstatus` },
  GetProvisionedRequestatus: (requestId: string) => { return `api/request/${requestId}/approvalstatus` },
  GetPolicyInfoFromPolicyServiceByRole: (userId: string, roleId: string, tenantId: string) => { return `api/user/${userId}/role/${roleId}/tenant/${tenantId}/approvalstatus` },
  GetExternalAADMappingAccessRequest: (userId: string, roleId: string, tenantId: string, attribute: string) => { return `api/user/${userId}/role/${roleId}/tenant/${tenantId}/attribute/${attribute}/accessrequest` },
  SaveAccessRequest: () => { return `api/request/save` },
  SaveAccessRenewal: () => { return `api/request/save/renew` },
  BulkRemoval: () => { return `api/request/bulkremove` },
  SaveReviewCreationRequest: () => { return `api/request/savereview` },
  UpdateMSSalesApprover: () => { return `api/request/updateapprover` },
  GetTenantConfig: () => { return `api/request/tenantconfig` },
  GetRolesForTenantConfig: () => { return `api/request/rolesfortenantconfig` },
  GetKarnakGroupConfig: () => { return `api/request/karnakgroupconfig` },
  GetEnvironmentConfig: () => { return `api/request/environmentconfig` },
  GetMSTRoleGroupConfig: () => { return `api/request/mstrolegroupconfig` },
  GetSubsidiaries: () => { return `api/request/subsidiaries` },
  GetBusiness: () => { return `api/request/business` },
  GetBusinessByTenant: (tenantId: string) => { return `api/request/business/${tenantId}` },
  GetCosmosRoleDefinitions: (tenantId: string) => { return `api/request/roles/cosmos/${tenantId}` },
  GetFlightConfig: () => { return `api/request/flightconfig` },
  GetQuizValidationConfig: () => { return `api/request/quizvalidation` },
  ValidateUserJobTitle: (userUpn: string) => { return `api/request/${userUpn}/validate` },
  SearchUser: (contentType: string, searchText: string, hideCEO: boolean) => { return `api/request/search/${contentType}/${searchText}/${hideCEO}` },
  SearchExternalUser: (searchText: string) => { return `api/request/search/externalUser/${searchText}` },
  SearchAllUser: (searchText: string) => { return `api/request/search/allUser/${searchText}` },
  SearchNonSCUser: (searchText: string) => { return `api/request/search/nonscuser/${searchText}` },
  SearchSupplier: (searchText: string) => { return `api/request/search/supplier/${searchText}` },
  InviteUser: () => { return `api/request/invite` },
  RemoveRoleAccess: () => { return `api/request/remove/selfremoval` },
  RemoveAccessRequestByAdmin: () => { return `api/request/remove/removebyadmin` },
  GetInProgressUsers: (tenantId: string) => { return `api/request/users/inprogress/${tenantId}` },
  GetInProgressUserRequestCount: (tenantId: string) => { return `api/request/users/inprogress/${tenantId}/count` },
  GetProvisionedUsers: (tenantId: string) => { return `api/request/users/provisioned/${tenantId}` },
  GetAccessRequestsForTenant: (tenantId: string) => { return `api/request/${tenantId}/accessrequestsfortenant` },
  GetFilteredFDLreport: () => { return `api/request/filteredfdlreport` },
  GetFilteredReviewreport: () => { return `api/request/reviewreport` },
  GetAssetMappingsForTenant: () => { return `api/request/assetMappings` },
  RemoveInProgressAccessRequest: (requestId: string) => { return `api/request/remove/accessrequest/${requestId}` },
  CancelReview: (reviewId: string) => { return `api/request/cancelreview/${reviewId}` },
  RemoveInProgressAccessRequestAdmin: (tenantId: string, requestId: string, userPrincipalId: string) => { return `api/request/remove/accessrequest/${tenantId}/${requestId}/${userPrincipalId}` },
  GetRequestHistory: (tenantId: string, userPrincipalId: string) => { return `api/request/${tenantId}/history/${userPrincipalId}` },
  GetAuthActivityLog: (tenantId: string) => { return `api/admin/authactivity/${tenantId}` },
  GetFTEManager: (userUpn: string) => { return `api/request/ftemanager/${userUpn}` },
  GetManager: (userUpn: string) => { return `api/request/manager/${userUpn}` },
  CheckManagerAlias: (searchText: string, hideDirectFTE: boolean) => { return `api/request/IsManager/${searchText}/${hideDirectFTE}` },
  GetUserData: (tenantId: string, userUpn: string) => { return `api/request/${tenantId}/graph/${userUpn}` },
  UpdateSupplierUserObjectId: () => { return `api/request/updateobjectid` },
  GetRequestData: (requestId: string) => { return `api/request/requestid/${requestId}` },
  GetMSRUserData: () => { return `api/request/msruser` },
  GetVLAppsConfig: () => { return `api/request/vlappsconfig` },
  GetPartnerCenterConfig: () => { return `api/request/PartnerCenterConfig` },
  GetKarnakFunctionConfig: () => { return `api/request/KarnakFunctionConfig` },
  GetAllowedDomains: () => { return `api/request/AllowedDomains` },
  GetMercuryConfig: () => { return `api/request/mercuryconfig` },
  GetPlancastConfig: () => { return `api/request/plancastconfig` },
  GetMercuryFAQConfig: () => { return `api/request/MercuryFAQConfig` },
  GetDisplayAccessReviewConfig: (tenantId: string) => { return `api/request/displayaccessreview/${tenantId}` },
  GetMercuryData: () => { return `api/request/permissions` },
  GetPlancastData: () => { return `api/request/plancastdata` },
  GetMercuryRoles: () => { return `api/request/getroles` },
  GetPlancastRoles: () => { return `api/request/getplancastroles` },
  GetCountryCompanyMapping: () => { return `api/request/CountryCompanyMapping` },
  GetRegionCountryCompanyMapping: () => { return `api/request/RegionCountryCompanyMapping` },
  GetMDSRolesAndScopes: (role : string) => { return `api/request/MDSRolesAndScopes/${role}` },
  HasConsildatedGaapAccess: (tenantId: string, roleId: string) => { return `api/request/hasconsildatedgaapaccess/${tenantId}/${roleId}` },
  GetMSSalesConfig: () => { return `api/request/MSSalesConfig` },
  GetMSApprovalLink: () => { return `api/request/MSApprovalLink` },
  GetMSSalesTestConfig: () => { return `api/request/MSSalesTestConfig` },
  GetAllowedAccountTypesConfig: () => { return `api/request/AllowedAccountTypes` },
  GetPolicyBasedOnPolicyId: (policyId: string) => { return `api/policy/${policyId}` },
  ValidateFile: () => { return `api/request/filedata` },
  CheckBulkUploadAccess: (tenantId: string, businessGroup: string) => { return `api/tenant/${tenantId}/businessGroup/${businessGroup}/isBusunessOwner` },
  IsDuplicateReviewName: (tenantId: string, reviewName: string) => { return `api/request/IsDuplicate/${tenantId}/${reviewName}` },
  AddSupplierUser: () => { return `api/request/addsupplieruser` },
  GetSupplierRequestId: (supplierId: string) => { return `api/request/supplierId/${supplierId}` }
}

export const telemetryHook: any = {
  trackMetric: function (metric: IMetricTelemetry, customProperties?: { [key: string]: unknown }) {
    // console.log('trackMetric: ' + metric);
  },
  trackException: function (exception: IExceptionTelemetry) {
    // console.log('trackException: ' + exception);
  },
  trackEvent: function (event: IEventTelemetry, customProperties?: { [key: string]: unknown }) {
    // console.log('TrackEvent: ' + event);
  },
  trackTrace: function (trace: ITraceTelemetry, customProperties?: { [key: string]: unknown }) {
    // console.log('trackTrace: ' + trace);
  }
};

export const Utility = {
  getDynamicColumnSize: (currentValue) => {
    let widnowSize = window.innerWidth;
    if (widnowSize < 1400) {
      return currentValue * 2;
    } else {
      return currentValue;
    }
  },
  openFDA: () => {
    // Load the finance bot.
    var fdaBotHostURL: string;
    var fdaBotHostAppId: string;
    var loadFDAScript = false;

    console.log("Loading bot");

    if (fdaBotHostAppId == undefined || fdaBotHostAppId == null) {
      fdaBotHostAppId = localStorage.getItem("FDABotHostID");
    }

    if (fdaBotHostURL == undefined || fdaBotHostURL == null) {
      fdaBotHostURL = localStorage.getItem("FDABotHostURL");
    }
    try {
      loadDynamicScript(fdaBotHostURL, () => {
        OpenFDA(`hostappid=${fdaBotHostAppId}`, { top: (window.screen.availHeight / 2) - 300, left: (window.screen.availWidth / 2) - 300 });
        loadFDAScript = true;
        console.log("Loading bot complete");
      });
    } catch (ex) {
      console.log("Something went wrong while loading the bot" + ex);
    }
  },
  GetTenantData: () => {
    let tenantConfigData = sessionStorage.getItem(UIConstants.Configuration.TenantConfig);
  //  if (tenantConfigData!=null) {
      let tenantData = Utility.getParsedTenantList(tenantConfigData);

      //Custom Feature flight Validation
      //if (!Utility.isFlightUser('MS Sales UI POC')) {
      //  tenantData = tenantData.filter(x => x.TenantName !== "MSSales")
      //} else {

      //}
      return tenantData;
    //} else {
    //  return [];
    //}
  },
  getParsedTenantList: (tenantConfigValue: string) => {
      let tenantList: ITenant[] = [];
    tenantList = JSON.parse(tenantConfigValue.replace(/'/g, `"`))
    //let tenantConfig = tenantConfigValue.split(';');
    //for (const iterator of tenantConfig) {
    //  if (iterator) {
    //    let item = iterator.split(',');
    //    let tenantId = item.filter(x => x.toUpperCase().indexOf("TENANTID") !== -1)[0]?.split(":")[1];
    //    let tenantName = item.filter(x => x.toUpperCase().indexOf("TENANTNAME") !== -1)[0]?.split(":")[1];
    //    let tenantDisplayName = item.filter(x => x.toUpperCase().indexOf("TENANTDISPLAYNAME") !== -1)[0]?.split(":")[1];
    //    let enableDelegation = item.filter(x => x.toUpperCase().indexOf("ENABLEDELEGATION") !== -1)[0]?.split(":")[1] === "true";
    //    let enableVLAppsFeatures = item.filter(x => x.toUpperCase().indexOf("ENABLEVLAPPSFEATURES") !== -1)[0]?.split(":")[1] === "true";

    //    if (tenantList.filter(x => x.TenantId === tenantId).length === 0)
    //        tenantList.push({ TenantId: tenantId, TenantName: tenantName, TenantDisplayName: tenantDisplayName, EnableDelegation: enableDelegation, EnableVLAppsFeatures: enableVLAppsFeatures });
    //  }
    //}
    return tenantList;
  },
    getParsedFDLEnvironmentConfig: (): IDropdownOption[] => {
        let envConfig = [];
        let envConfigData = sessionStorage.getItem(UIConstants.Configuration.EnvironmentConfig).replace("}]", "").split('},{');
        for (const iterator of envConfigData) {
            if (iterator) {
                let item = iterator.split(',');
                envConfig.push({
                    key: item.filter(x => x.toUpperCase().indexOf("'TENANTAPPLICATIONID'") !== -1)[0]?.split(":")[1].replaceAll("'", ""),
                    text: item.filter(x => x.toUpperCase().indexOf("'ENVIRONMENTNAME'") !== -1)[0]?.split(":")[1].replaceAll("'", "")
                })
            }
        }
        return envConfig;
  }, isFlightUser: (featureName): boolean => {
    let isFlightUser: boolean = false;
    let flightConfig = localStorage.getItem("tenantFlighting");
    if (flightConfig) {
      let flight = JSON.parse(flightConfig);
      let filteredFeature = flight.filter(x => x.Name == featureName);
      if (filteredFeature.length > 0) {
        let flightUsers: [] = filteredFeature[0].FlightUsers;
        let currentUser = JSON.parse(localStorage.getItem("currentUser"));
        isFlightUser = flightUsers.findIndex(x => x == currentUser.userName) >= 0;
      }
    }
    return isFlightUser;
  }
}
