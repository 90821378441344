import { List } from "@fluentui/react";
import IRole from "./Role.model";
import { BulkRequest } from "./Role.model";
import { Dictionary } from "lodash";

export default interface IUserAccess {
    tenantId: string;
    tenantName: string;
    userAlias: string;
    startDate: Date;
    endDate: Date;
    principalId: string;
    email: string;
    attributeNameValue: IRole;
    businessJustfication?: string;
    scope: string;
}
export class Properties {
    publisherName: string;
    publisherRequestId: string;
    ppeRequestId?: string;
    consumerRequestId?: string;
    publisherServiceTreeId?: string;
    publisherServiceTreeName?: string;
    serviceName?: string;
    computeType?: string;
    accountIdType?: string;
    accountId?: string;
    securityGroup?: string;
    serviceTreeName?: string;
    servicetreeId?: string;
    opsEngAlias?: string;
    devEngAlias?: string;
    isSOX?: boolean;
    isCplusAI?: boolean;
    hasClassifiedData?: boolean;
    hasPersonalData?: boolean;
    dataContractLinks: Array<string>;
    type?: string;
    consumerOwners?: Array<string>;
    editRequest?: boolean;
    bulkRequestUserDetails: Array<BulkRequestUserDetails>;
    onBehalfExternalUser?: string;
}
export class BulkRequestUserDetails {
    userUPN: string;
    roleName: string;
}
export class Asset {
    AssetId?: string;
    AssetName: string;
    EntityName: string;
    DataSourceName?: string;
    SchemaName?: string;
    DataClassificationLevel: number;
    PersonalDataField?: string;
    IsDataOwnerApprovalRequired?: boolean;
    PublisherName?: string;
    PublisherServiceTreeId?: string;
}
export class Approvers {
    DataOwners: Array<string>;
    PublisherPrivacyLeads: Array<string>;
    ExecutiveSponsor: Array<string>;
    ConsumerPrivacy: Array<string>;
}
export class AccessRequestModel {
    id?: string;
    tenantId: string;
    tenantName: string;
    requestorUPN?: string;
    requestorEmail?: string;
    requestorPrincipal: string;
    requestedBy: string;
  mode?: string;
    startDate?: any;
    endDate?: any;
    role: RoleDetails;
    properties?: Properties;
    Approvers?: Approvers;
    scope: string;
    businessJustification?: string;
    assets?:Asset[]
    status?: string;
    requestedDate?: any;
    completedDate? : any;
    requestType: string;
}
export class AccessRequestSubmissionModel {
    editRequest?: boolean;
    tenantId: string;
    tenantName: string;
    role: RoleDetails;
    scope: string;
    businessJustification?: string;
    requestType: string;
    signatureVerified: boolean;
    termsAndConditionsApplied: boolean;
    alternateMangerAlias?: string;
    managerAlias?: string;
    allowSkipLevel?: boolean;
    requestorPrincipalId?: string;
    bulkRequestUserDetails?: BulkRequest[];
    onBehalfExternalUser?: string;
}
export class BulkRemoval {
    userDetails: string;
    tenantName: string;
    businessJustification: string;
}
export class RoleDetails {
    typeId?: string;
    type?: string;
    groupId?: string;
    group?: string;
    roleId: string;
    roleName: string;
    roleDescription: string;
    attributes: Array<Attributes>;
    actions: Array<string>;
    businessGroup?: string;
    approvalPolicy?: string;
}
export class Attributes {
    attributeName: string;
    attributeValues: Array<AttributeValues>;
}
export class AttributeValues {
    code: string;
    description: string;
    GroupCode?: string;
    GroupDescription?: string;
    Id?: number;
    GroupId?: number;
    isFlag?: boolean;
    isRemove?: boolean
}
export enum RequestType {
    Add = 'Add',
    Remove = 'Remove',
}

export class MyAccessRequestResponse {
    IsDataLoaded: boolean;
    IsSuccess: boolean;
    Data: AccessRequestModel[];
}

export class RequestDetailsResponse {
    IsDataLoaded: boolean;
    IsSuccess: boolean;
    Data: RequestDetails;
}
export class UpdateApprover {
    id: string;
    Approver: string;
    Manager: string;
}
export class RequestDetails{
    id:string;
    tenantId:string;
    tenantName:string;
    requestorAlias:string;
    requestorUPN:string;
    requestorEmail:string;
    requestorPrincipal:string;
    startDate:string;
    endDate:string;
    requestedDate:string;   
    role: RoleDetails;
    properties: any ; //{alternateManagerAlias:sonalip,managerAlias:devnat}
    businessJustification:string;
    status:string;
}
export class AccessRequestRemovalByAdminModel {
    tenantId: string;
    roleId: string;
    roleAssignmentId: string;
    userId: string;
    businessGroup: string;
    businessJustification: string
}
export class AccessRequestSelfRemoval {
    tenantId: string;
    roleId: string;
    businessGroup: string;
    permissionLevel?: string;
    roleAssignmentId?: string;
};
export class FDLReportFilter {
    tenantId: string;
    status?: string;
    userPrincipalId?: string;
    requestId?: string;
    publisherRequestId?: string;
    publisherServiceTreeName?: string;
    consumerServiceTreeId?: string;
    consumerRequestId?: string;
    assetName?: string;
}
export class ReviewReportFilter {
    tenantId: string;
    fiscalYear?: string;  
}
export class BusinessGroupModel {
    Id: string;
    Name: string;
}
export class RoleModel {
    Id: string;
    Name: string;
}

export class ReviewRequestSubmissionModel {
    reviewName: string;
    tenantId: string;
    tenantName: string;
    role: Array<RoleModel>;
    businessGroup: Array<BusinessGroupModel>;
    fiscalYear: string;
    quarter: string;
    startDate: string;
    endDate: string;
    businessJustification?: string;  
    isManagerchecked: boolean;
    approvers: Array<string>;
    users: Array<string>;
}

export class ReviewResponse {
    IsSuccess: boolean = true;
    IsDataLoaded: boolean = false;
    Data: ReviewRequestSubmissionModel[];
}
export class MercuryConfig {
    MercuryFBITeam: string;
    EnableDRSForm: boolean;
}
export class Policy {
    id: string
    name: string
    tenantId: string
    approval: approval
}

export class PolicyData {
    code:string
    description: string
    approvers: string
}
export class approval {

    levels: number
    levelInfo: levelInfo[]
}
export class levelInfo {
    levelId: number
    levelType: string
    LevelCategory: string
    type: number
    value: string
    levelScope: levelScope[]
    backUpApprovers: string
    external_Users: string
    externalUsers_ApproversList: string
}
export class levelScope {
    code: string
    description: string
}
