import React from 'react';
import {
    IColumn,
    Fabric,
    DetailsListLayoutMode,
    SelectionMode,
    ShimmeredDetailsList,
    mergeStyleSets,
    Label,
    Link
} from '@fluentui/react';
import { RouteComponentProps } from 'react-router-dom';
import { msalAuth } from '../../shared/auth/MsalAuthProvider';
import { IUser } from '../../shared/models/User.model';
import { MyAccessRequestCollection } from '../../shared/models/MyAccessRequest.model';
import { UIConstants } from '../../shared/models/Constants';
import AccessRequestDetails from './AccessRequestDetails'
import ITenant from '../../shared/models/Tenant.model';
import { Utility } from '../../shared/models/Helper';
import AccessAPI from '../../store/AccessAPI';
import { MyAccessRequestResponse } from '../../shared/models/UserAccess.model';
import ConfigurationAPI from '../../shared/api/Configuration.api';
import { AllowedAccount } from '../../shared/models/Role.model';
const classNames = mergeStyleSets({
    compactCard: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    expandedCard: {
        padding: '0px 24px'
    },
    item: {
        selectors: {
            '&:hover': {
                textDecoration: 'underline',
                cursor: 'pointer'
            }
        }
    }
});

export interface MyAccessList {
    key: number;
    userName: string;
    role: string;
    requestedOn: string;
    status: string;
    remarks: string;
}

export interface MyAccessProps {
    applicationData: any;
    getAppData: any;
}

export interface MyAccessState {
    items: MyAccessList[];
    // selectionDetails: string;
    selectedPageIndex: number;
    userDetails: IUser;
    showModal: boolean;
    requestId: string;
    requestedBy: string;
    data: MyAccessRequestCollection,
    AccessData: MyAccessRequestResponse,
    allowedAccountTypes: AllowedAccount[],
    tenantName: string;
}
type MyAccessRequestProps = MyAccessProps & MyAccessState
    & RouteComponentProps<{}>;


export default class MyAccessRequests extends React.Component<MyAccessRequestProps>  {
    private _allItems: MyAccessList[] = [];
    private tenantData: ITenant = null;
    private accessHistoryPageRef: any;

    public async componentDidMount() {
        let userAccount = msalAuth.getAccount();
        let user: IUser = { PrincipalId: userAccount.accountIdentifier, Alias: userAccount.userName, Name: userAccount.name };
        this.setState({ userDetails: user });
        let TenantData = Utility.GetTenantData();
        let tenantName = (this.props.match.params as any).tenantName;
        this.setState({ tenantName: tenantName })
        tenantName = tenantName.toUpperCase();
        this.tenantData = TenantData.filter(x => x.TenantName.toUpperCase() === tenantName)[0];
        this.ensureDataFetched();
    }
    private ensureDataFetched() {
        let tenantId = this.tenantData?.TenantId;
        if (tenantId) {
            if (this.tenantData.isExternalAADTenant) {
                ConfigurationAPI.getConfigurationByKey(UIConstants.Configuration.AllowedAccountTypes).then(response => {
                    let config = response ? JSON.parse(response.replace(/'/g, `"`)) : null;
                    if (config != null && config.length > 0) {
                        let allowedTypes = config.filter(x => x.TenantName?.toLowerCase() == this.tenantData?.TenantName.toLowerCase())
                        this.setState({ allowedAccountTypes: allowedTypes?.length > 0 ? allowedTypes[0].AllowedDomains : [] })
                    }
                })
            }
            AccessAPI.getMyAccessRequest(tenantId).then(res=>{
                this.setState({ AccessData: res });
            });
        } else {
            // Error
        }
    }
    constructor(props: any) {
        super(props);
        this.state = {
            items: this._allItems,
            selectedPageIndex: 0,
            userDetails: null,
            showModal: false,
            requestId: '',
            requestedBy: '',
            data: null,
            AccessData: { Data: [], IsDataLoaded: false, IsSuccess: true },
            allowedAccountTypes: [],
            tenantName: ''
        };
    }
    public state: MyAccessState = {
        userDetails: null,
        items: this._allItems,
        selectedPageIndex: 0,
        showModal: false,
        requestId: '',
        data: null,
        AccessData: null,
        requestedBy: '',
        allowedAccountTypes: [],
        tenantName: ''
    }

    public render(): JSX.Element {
        const { AccessData, showModal } = this.state;
        return (<React.Fragment>
            <div id={'div-msg-area'} style={{ height: 50 }}></div>
            <div aria-label="My Request History Page Opened" style={{ outline: 'none' }} tabIndex={-1} ref={this.accessHistoryPageRef} id={'div-myaccess'}>
                <Fabric style={{
                    maxHeight: '80vh',
                    overflow: 'auto',
                    display: 'block',
                    margin: '0 auto',
                }} >
                    <Label style={{ paddingLeft: 10, fontSize: 20 }} ><h1 className={"h1"} style={{ paddingLeft: 0, fontSize: 20, margin: 0 }}>{UIConstants.PageTitle.MyRequestHistory}</h1></Label>
                    <ShimmeredDetailsList
                        setKey="items"
                        columns={this.getColumnDefintion()}
                        items={this.getItems()}
                        layoutMode={DetailsListLayoutMode.justified}
                        selectionMode={SelectionMode.none}
                        enableShimmer={!AccessData?.IsDataLoaded}
                        ariaLabelForShimmer="Content is being fetched"
                        ariaLabelForGrid="My Request History details"
                        listProps={{ renderedWindowsAhead: 0, renderedWindowsBehind: 0 }}
                        detailsListStyles={{ root: { overflow: 'visible' } }}
                    />
                    {(showModal) && <AccessRequestDetails data={this.state.data} requestId={this.state.requestId} requestedBy={this.state.requestedBy} isAdminScreen={false} {...this.props} onDismiss={() => { this.setState({ showModal: false }) }} />}
                    {this.setFocusOnPageHeader()}
                </Fabric>
            </div>
        </React.Fragment>

        );
    }

    private setFocusOnPageHeader() {
        setTimeout(() => {
            this.accessHistoryPageRef.current.focus()
        }, 2000)
    }

    private _onRenderItemColumn = (item: any, index: number, column: IColumn): JSX.Element | React.ReactText => {
        if (column.key === 'status') {
            return (
                <div className={classNames.item}>
                    <Link role="button" aria-label={`${item.status} ${item.requestId}`} onClick={() => { this.setState({ showModal: true, requestId: item.requestId, data: item, requestedBy: item.requestedBy }) }} > {item.status} </Link>
                </div>
            );
        }
        if (column.key === 'completedDate') {
            return (
                <span role="cell" aria-roledescription={item.completedDate === '-' ? 'Not Applicable' : item.completedDate}>{item.completedDate}</span>
            );
        }

        if (column.key == 'requested for' && (this.tenantData?.isExternalAADTenant && this.state.tenantName.toLowerCase() != UIConstants.Tenant.MST.toLowerCase())) {
            return (<div>{(!item.role.includes('Bulk Request') && item.requestedFor == null) ? item.requestedBy + '@microsoft.com' : (item.requestedFor != null) ? item.requestedFor : 'On Behalf (Bulk Request)'}</div>)
        }

        return item[column.key];
    };

    private getItems() {
        const { AccessData, userDetails } = this.state;
        let tenantId = this.tenantData?.TenantId;
        let items: Array<MyAccessRequestCollection> = [];
        AccessData.Data && AccessData.Data.forEach(accessItem => {
            let role = accessItem.role;
            if (role !== null) {
                if (this.tenantData?.TenantName != UIConstants.Tenant.Mercury || (this.tenantData?.TenantName == UIConstants.Tenant.Mercury && role.group != null)) {
                    if (this.tenantData?.TenantName != UIConstants.Tenant.MSPlancast || (this.tenantData?.TenantName == UIConstants.Tenant.MSPlancast && role.type != null))
                    {
                        items.push({
                            requestId: accessItem.id,
                            roleDesc: role.roleDescription,
                            role: accessItem.tenantName == UIConstants.Tenant.Mercury && role.group != role.roleName ? `${role?.group}-${role?.roleName}` : (accessItem.tenantName == UIConstants.Tenant.MSPlancast && role.type != role.roleName ? `${ role?.type }:${ role?.roleName }` : role.roleName),
                            roleId: role.roleId,
                            requestedBy: (accessItem.requestedBy ?? userDetails.Alias).split("@")[0],
                            mode: accessItem.mode,
                            businessJustification: accessItem.businessJustification,
                            requestType: (accessItem.tenantName == UIConstants.Tenant.Mercury) ? ((accessItem.businessJustification.trim() == 'SelfRemoval' || accessItem.businessJustification.trim() == 'AdminRemoval' || accessItem.mode == 'Admin') ? UIConstants.RequestType.Remove : (accessItem.properties?.editRequest ? "Update" : "Add")) : accessItem.requestType,
                            attributes: role.attributes && role.attributes.length === 0 ? 'NA' : role.attributes,
                            requestDate: new Date(accessItem.requestedDate).toLocaleString(),
                            completedDate: accessItem.completedDate ? new Date(accessItem.completedDate).toLocaleString() : "Not Applicable",
                            status: accessItem.status,
                            userId: userDetails.PrincipalId,
                            user: userDetails.Alias,
                            properties: accessItem.properties,
                            requestedFor: (this.tenantData?.isExternalAADTenant && this.state.tenantName.toLowerCase() != UIConstants.Tenant.MST.toLowerCase()) ? accessItem.properties?.onBehalfExternalUser : null
                        })
                    }
                }
            }
        });
        let sortedItems = this._copyAndSort(items, 'requestDate', true);
        return sortedItems;
    }
    private getColumnDefintion() {
        let cloumnDefs = [
            { key: 'requestId', name: 'Request ID', fieldName: 'requestId', minWidth: 250, maxWidth: 250, isResizable: false },
            { key: 'role', name: 'Role', fieldName: 'role', minWidth: 250, maxWidth: 250, isResizable: false },
            { key: 'requestType', name: 'Request Type', fieldName: 'requestType', minWidth: 110, maxWidth: 110, isResizable: true },
            { key: 'requestedBy', name: 'Requested By', fieldName: 'requestedBy', minWidth: 110, maxWidth: 110, isResizable: true },
            { key: 'requestDate', name: 'Requested Date', fieldName: 'requestDate', minWidth: Utility.getDynamicColumnSize(100), maxWidth: Utility.getDynamicColumnSize(200), isResizable: false },
            { key: 'completedDate', name: 'Completed Date', fieldName: 'completedDate', minWidth: Utility.getDynamicColumnSize(100), maxWidth: Utility.getDynamicColumnSize(200), isResizable: false },
            { key: 'status', name: 'Status', fieldName: 'status', minWidth: 150, maxWidth: 150, isResizable: false, onRender: this._onRenderItemColumn },
        ];

        if (this.tenantData?.isExternalAADTenant && this.state.tenantName.toLowerCase() != UIConstants.Tenant.MST.toLowerCase()) {
            cloumnDefs.splice(2, 0, { key: 'requested for', name: 'Requested For', fieldName: 'requested for', minWidth: 200, maxWidth: 200, isResizable: true, onRender: this._onRenderItemColumn });
        }

        return cloumnDefs
    }

    private _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
        const key = columnKey as keyof T;
        if (columnKey === 'completedDate' || columnKey === 'requestDate') {
            return items.slice(0).sort(function (a: T, b: T) {
                var c: any = new Date(a[columnKey]);
                var d: any = new Date(b[columnKey]);
                return isSortedDescending ? d - c : c - d;
            });

        } else {
            return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
        }
    }
}
